import React from "react"
import t from "prop-types"
import { ChevronDown, ChevronUp } from "react-feather"
import Collapsible from "./Collapsible"

export const Dropdown = ({ title, text, count, isHtml = false }) => {
  const [state, setState] = React.useState(false)

  return (
    <li
      className={`block border-b font-body border-gray-200
    		${state ? "opacity-100" : "opacity-75"}`}
    >
      <h4
        className={`text-base md:text-lg select-none justify-between py-4 flex
      		${state ? "font-bold" : ""}`}
      >
        <span className="w-12">{count}.</span>
        <span className="flex-1">{title}</span>
        <button
          className="appearance-none px-2 bg-transparent hover:bg-light_1 border-none focus:outline-none"
          onClick={() => setState(!state)}
        >
          {state ? <ChevronUp /> : <ChevronDown />}
        </button>
      </h4>
      <Collapsible expand={state}>
        <div className="pb-4 pl-12 opacity-75 pt-0">
          {isHtml ? (
            <div
              className="pb-4 opacity-75 pt-0"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          ) : (
            text
          )}
        </div>
      </Collapsible>
    </li>
  )
}

Dropdown.propTypes = {
  title: t.string.isRequired,
  text: t.string.isRequired,
  count: t.string.isRequired,
  isHtml: t.bool,
}
