import React from "react"
import * as R from "ramda"
import { graphql, useStaticQuery } from "gatsby"
import { Dropdown } from "./Dropdown"
import { H2 } from "../Typography"

export function FAQ() {
  const { allContentfulFaqs: faqs } = useStaticQuery(graphql`
    query GetAllFAQS {
      allContentfulFaqs {
        nodes {
          order
          title
          content {
            content
          }
        }
      }
    }
  `)

  return (
    <section className="container mx-auto px-4 mb-12">
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-2/6">
          <H2 id="#faq" className="md:sticky mb-4" style={{ top: 40 }}>
            Frequently <br className="hidden md:block" /> Asked <br /> Questions
          </H2>
        </div>
        <div className="flex-1">
          {faqs.nodes
            .sort((a, b) => a.order - b.order)
            .map(({ title, content, order }) => (
              <Dropdown
                key={title}
                count={appendNum(order)}
                title={title}
                text={content.content}
              />
            ))}
        </div>
      </div>
    </section>
  )
}

export const appendNum = R.when(
  R.compose(R.equals(1), R.length, String),
  num => `0${num}`
)
