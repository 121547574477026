import * as React from "react"
import * as R from "ramda"
import { useStaticQuery, graphql } from "gatsby"

import { FAQ } from "../components/Website/Faqs"
import Button from "../components/Buttons"
import { H1, H2 } from "../components/Typography"
import CourseCard from "../components/Courses/Card"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Pattern from "../components/Layout/Pattern"
import { PagerProvider } from "@wigxel/react-components/lib/layout"
import { Download } from "react-feather"
import useScroll from "../hooks/useScroll"
import Alumni from "../components/Alumni"

const IndexPage = () => {
  const scrollTo = useScroll()
  const {
    allContentfulTestimonials: testimonials,
    allContentfulCategory: categories,
  } = useStaticQuery(graphql`
    query AllContentQuery {
      allContentfulTestimonials {
        nodes {
          fullname
          id
          photo {
            title
            file {
              fileName
              url
            }
          }
          titleAndCompany
          content {
            content
          }
        }
      }

      allContentfulCategory(sort: { fields: order, order: ASC }) {
        nodes {
          categoryName
          order
          courses {
            id
            order
            price
            certification
            courseTitle
            duration
            description {
              raw
            }
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const sorts = {
    highestPricesFirst: (a, b) => a.order < b.order,
  }

  // console.info("categories", categories);

  return (
    <PagerProvider>
      <Layout>
        <SEO title="Home | CMK Culinary" />
        <section className="container mx-auto mb-24 py-24 flex flex-col md:flex-row items-start">
          <div className="px-4">
            <H1 className="mb-8">Professional Culinary School</H1>
            <p className="text-base md:text-xl w-5/6">
              Learn Culinary arts from an award winning culinary{" "}
              <br className="hidden md:block" /> institution. We teach every
              kind of culinary arts <br className="hidden md:block" />
              to people like you.
            </p>

            <Button
              primary
              className="mt-12 font-bold rounded-full"
              rounded
              onClick={() => {
                scrollTo(0, 0, document.querySelector("#courses"))
              }}
            >
              <span className="md:py-2 md:px-4">View Courses</span>
            </Button>
          </div>

          <div className="w-full mt-12 pb-12 lg:w-4/6 relative flex md:py-0 md:mt-0 md:pr-24">
            <Pattern size={32} className="w-full absolute h-24 bottom-0" />
            <figure
              className="w-1/2 bg-gray-50 transform shadow-xl
            	translate-x-12 translate-y-12
            	md:translate-x-32 md:translate-y-32 z-20"
              style={{
                height: "35vh",
                backgroundImage: `url(${
                  require("../images/pastry-2.jpg").default
                })`,
              }}
            />
            <figure
              className="w-1/2 bg-gray-50 shadow-lg transform -translate-x-12 md:translate-x-0 z-10"
              style={{
                height: "35vh",
                backgroundImage: `url(${
                  require("../images/pastry-3.jpg").default
                })`,
              }}
            />
          </div>
        </section>

        <section className="mx-auto flex flex-col md:flex-row container px-4 md:px-0">
          <div className="max-w-md flex-1">
            <div className="font-bold tracking-wider mb-0 opacity-50">
              WELCOME TO CMK
            </div>
            <p className="font-body text-3xl">
              <b className="text-_5">Skyrocket</b> your career in culinary arts
              today.
            </p>
          </div>
          <div className="flex-1 md:ml-6 lg:ml-12 mt-6">
            <p>
              We know what young, aspirant chefs and entrepreneurs need to
              succeed in the ever-expanding global hospitality environment.
              CMK's state-of-the-art institution are fully equipped with
              training kitchens, administration, air-conditioned office for
              relaxation:
            </p>
            <div className="flex py-8 justify-around">
              {[
                ["01.", "Excellent Centre", "Lagos"],
                ["02.", "Creative Centre", "Port Harcourt"],
              ].map(e => {
                return (
                  <div
                    key={e[0]}
                    className="flex flex-1 flex-col items-start opacity-75"
                  >
                    <span className="text-2xl font-bold">{e[0]}</span>
                    <span className="text-lg md:text-xl font-bold py-2 mt-0">
                      {e[1]}
                    </span>
                    <span className="uppercase text-xs md:text-sm">{e[2]}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </section>

        <section className="container mx-auto  py-12 md:py-24 px-4 md:px-0">
          <h2
            id="courses"
            className="font-display font-bold tracking-tight text-3xl mb-0 lg:text-6xl md:mb-4"
          >
            Our Courses
          </h2>
          <p className="text-lg md:text-xl md:pl-2 mb-4">
            Pick a course that suits you
          </p>
          {categories.nodes.map(({ categoryName, courses }) => (
            <div key={categoryName} className="mb-12 lg:mb-24">
              <h4 className="mb-8 text-lg uppercase tracking-widest opacity-50 border-b pb-4 text-right">
                {categoryName}
              </h4>
              <div className="grid place-items-stretch pr-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-12 mb-8">
                {courses
                  .sort(sorts.highestPricesFirst)
                  .map(({ courseTitle, duration, price, id, ...a }, idx) => {
                    const firstImage = R.head(a?.image || [])?.file?.url
                    console.log(a?.image)
                    return (
                      <CourseCard
                        key={idx}
                        price={price}
                        courseId={id}
                        image={firstImage}
                        title={courseTitle}
                        benefits={[duration]}
                      />
                    )
                  })}
              </div>
            </div>
          ))}
        </section>

        <section className="flex container bg-red-50 md:rounded-2xl flex-col space-y-6 md:space-y-0 md:space-x-6 md:flex-row mx-auto px-12 py-12 lg:px-24 lg:py-12 items-center mb-24 justify-between">
          <div className="flex flex-col md:max-w-sm">
            <h2
              id="get-started"
              className="font-sans font-bold tracking-tight text-xl mb-2 lg:text-3xl"
            >
              Need a Spark?
            </h2>
            <p className="text-sm opacity-75">
              Download CMK's brochure that contains insightful information about
              the briefs, values, culture, courses, questions and schedule about
              Culinary Institute.
            </p>
          </div>
          <a
            href="https://bit.ly/cmk-brochure-pdf"
            referrerPolicy="no-referrer"
            className="w-full md:w-auto"
            download
          >
            <Button primary fullwidth aria-label="Download Brochure">
              <Download aria-label="Download Icon" />
              &nbsp;&nbsp;See Brochure
            </Button>
          </a>
        </section>

        {/* Alumni */}
        <Alumni testimonials={testimonials} />

        <section className="py-12 px-4 md:px-0 mt-12 bg-opacity-25 overflow-hidden lg:overflow-visible">
          <div className="container flex flex-col lg:flex-row items-start mx-auto my-24 relative z-10">
            <div className="flex-1 mb-8 items-end">
              <H2 className="relative z-20">
                Accredition <br /> & Partners
              </H2>
              <Pattern
                size={40}
                className="w-64 h-64 absolute transform translate-x-24 z-10 rotate-45 opacity-50 -translate-y-24"
              />
            </div>

            <div className="grid grid-cols-3 items-center gap-5 md:gap-12">
              {[
                [require("../images/city-and-guilds.png"), "City & Guilds"],
                [require("../images/eko.jpg"), "Eko Hotels & Suites"],
                [require("../images/edenlife.svg"), "Our Eden Life"],
                [
                  require("../images/iaccp.jpg"),
                  "International Association of Culinary Professionals",
                ],
                [
                  require("../images/young-chef-olympiad.png"),
                  "Young Chef Olympiad India",
                ],
                [require("../images/sonia.jpg"), "Sonia Food Industries"],
              ].map(([filename, altText]) => (
                <div
                  className="flex items-center justify-center border h-24 md:h-40 relative z-20 bg-white"
                  key={filename.default}
                >
                  <img
                    src={filename.default}
                    alt={altText}
                    className={`object-contain ${
                      filename === "edenlife.svg" ? "h-20" : "h-32"
                    }`}
                  />
                </div>
              ))}
            </div>
          </div>
        </section>

        <FAQ />
      </Layout>
    </PagerProvider>
  )
}

export default IndexPage
